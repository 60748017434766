<template>
  <div class="editor" v-if="editor">
    <menu-bar class="editor__header" :editor="editor" :task="task" />
    <editor-content class="editor__content" :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import { taskApi } from '../../api';
import { mapState } from 'vuex';
import { $themeConfig } from '@themeConfig';
import Focus from '@tiptap/extension-focus';
import ListItem from '@tiptap/extension-list-item';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Highlight from '@tiptap/extension-highlight';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import Placeholder from '@tiptap/extension-placeholder';
import Blockquote from '@tiptap/extension-blockquote';
import { updateTask, dataTask } from '@/mixins/task';
import MenuBar from './MenuBar.vue';

export default {
  name: 'QuillTextEditor',
  components: {
    EditorContent,
    MenuBar,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    task: {
      type: [Object],
      default: null,
    },
  },
  // mixins: [updateTask, dataTask],

  data() {
    return { editor: null };
  },
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;
      // console.log('value:', value);
      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)
      if (isSame) {
        return;
      }
      this.editor.commands.setContent(value, false);
    },
  },

  methods: {},

  mounted() {
    // console.log('value:', this.value);
    this.editor = new Editor({
      content: this.value,

      extensions: [
        StarterKit,
        /*CustomListItem,*/
        TaskList,
        TaskItem,
        Link,
        Highlight.configure({ multicolor: true }),
        TextStyle,
        Color.configure({
          types: ['textStyle'],
        }),
        Placeholder.configure({
          placeholder: 'Напишите что нибудь …',
        }),
        Focus.configure({
          className: 'has-focus',
          mode: 'all',
        }),
        Image.configure({
          inline: true,
          allowBase64: true,
        }),
      ],
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML());
        console.log('value:', this.editor.getHTML());
        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
      editorProps: {
        attributes: {
          class: 'm-0 p-1 pb-4',
        },
      },
      injectCSS: false,
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
.editor {
  display: flex;
  flex-direction: column;
  max-height: 150rem;
  min-height: 8rem;
  min-width: 18rem;
  color: #0d0d0d;
  background-color: #fff;
  border: 1.5px solid rgb(130, 134, 139);
  // border-radius: 0.75rem;
  // margin: 0 -0.35rem;
  &__header {
    display: flex;
    align-items: center;
    flex: 0 0 0 0;
    flex-wrap: wrap;
    // padding: 0.15rem;
    // padding-top: 0rem;

    border-bottom: 1.5px solid rgb(130, 134, 139);
  }

  &__content {
    // padding: 0.6rem 0.6rem;
    // padding-top: 0.6em;
    // flex: 1 1 1;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 90%;
    // background-color: pink;
    min-height: 6rem;
    // outline: none;
    // -webkit-overflow-scrolling: touch;
  }
}
/* Basic editor styles */
.ProseMirror {
  > * {
    margin: 0;
    padding: 0rem;
    // padding-left: 0.5em;
    // font-family: 'monospace';
    // font-size: 90%;
    color: rgb(75, 75, 75);
  }

  ul,
  ol {
    padding-left: 3rem;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    // padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  mark {
    background-color: #faf594;
  }

  img {
    max-width: 100%;
    width: auto;
    height: auto;

    &.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }
  }

  hr {
    margin: 1rem 0;
  }

  blockquote {
    padding-left: 1rem;
    margin-left: 1rem;
    border-left: 3px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  ol {
    li {
      margin-bottom: 0;
      p {
        margin: 0;
      }
    }
  }

  ul[data-type='taskList'] {
    list-style: none;
    padding-left: 0.5em;

    p {
      margin: 0;
    }

    li {
      display: flex;
      > label {
        display: flex;
        flex: 0 0 auto;
        margin-right: 0.8rem;
        margin: 0;
        padding-top: 0.3rem;
        user-select: none;
        align-items: start;
      }

      > div {
        flex: 1 1 auto;
      }
    }
  }
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
</style>
