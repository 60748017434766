import { taskApi } from "@/api";
import { mapState, mapActions, mapGetters } from "vuex";
import { getErrors } from "@/helpers/errors";
import axios from 'axios';

export const updateTask = {
		computed: {
				...mapState("project", ["currentProject", "isPending"])
		},
		methods: {
				...mapActions("project", ["getBacklogTasks", "getSprints", "getBoard", "getEpics"]),
				updateTask(number, data, isLoader = true, activeTask = false) {
						if(isLoader) this.$store.dispatch('project/updatePending', true);
						taskApi.updateTask(this.currentProject, number, data).then(() => {
								this.$bvToast.toast(`Задача ${this.currentProject}-${number} была обновлена`, {
										title: this.$t("Data Update"),
										variant: "success",
										solid: true,
								});
								if(activeTask) {
										taskApi.task(this.currentProject, this.task.number).then((res) => {
												this.$store.dispatch('project/updateActiveTask', res.data.task);
										});
								}
								if(this.$route.name == "board") {
										this.getBoard();
										this.$store.dispatch('project/updatePending', false)
								}
								this.$store.dispatch('project/updatePending', false)
								/*if(this.$route.name == "projects-backlog") {
										axios.all([this.getBacklogTasks(), this.getSprints(), this.getEpics()]).then(() =>
										{ this.$store.dispatch('project/updatePending', false)})
								}*/
						}).catch(error => {
								if(error.response) {
										const errors = getErrors(error.response.data.errors);
										if(errors.general) {
												this.$bvToast.toast(`Ошибка при обновлении задачи. ERROR: ${errors.general[0].status} ${errors.general[0].message}`, {
														title: this.$t("Data Update"),
														variant: "danger",
														solid: true,
												});
										}
								}
						});
				}
		}
};

export const dataTask = {
		data: function() {
				return {
						taskWorkflowStep: {},
				}
		},
		computed: {
				...mapGetters("project", ["statusEpicId"])
		},
		methods: {
				getTaskData(task) {
						const data = {
								name: task.name,
								description: task.description,
								estimate: task.estimate,
								check_in_build_ios: task.check_in_build.ios,
								check_in_build_android: task.check_in_build.android,
								priority_id: task?.priority?.id || null,
								//TODO доделать сохранение purpose
                purpose_id: task?.purpose?.id || null,
                story_point: parseInt(task.story_point),

						};
						/*if(this.taskWorkflowStep.hasOwnProperty("id")) {
								data.workflow_step_id = this.taskWorkflowStep.id;
						}*/
						
						if(task.labels) {
								if(Array.isArray(task.labels) && task.labels.length > 0) {
										data.label_id = task.labels[0].id;
								} else {
										data.label_id = task.labels.id;
								}
						} else {
								data.label_id = null;
						}
						
						if(task.epic && task.epic.hasOwnProperty("key")) {
								data.epic_number = task.epic.number;
						} else {
								if(task.type.id !== this.statusEpicId) {
										data.epic_number = null;
								}
						}
						
						
						if(task.sprint && task.sprint.hasOwnProperty("uuid")) {
								data.sprint_uuid = task.sprint.uuid;
						} else {
								data.sprint_uuid = null;
						}
						
						if(task.assignee && task.assignee.hasOwnProperty("id")) {
								data.assignee_id = task.assignee.id;
						} else {
								data.assignee_id = null;
						}
						return data;
				}
		},
};

export const backlogDnDEnd = {
		methods: {
				showMessage(event) {
						let message = event.to.dataset.isactive > 0 ? this.$t("You move the task to an active sprint")
								: (event.from.dataset.isactive > 0) ? this.$t("You are moving a task from an active sprint") : null;
						
						if(message) {
								this.$bvModal.show('warning-drag-modal');
								this.$nextTick(() => {
										document.getElementById("warningDragModalText").innerHTML = message;
								})
						}
				},
				getDnDRes(event) {
						let sprint = event.to.dataset.sprint;
						let task_number = event.clone.dataset.task;
						let to = sprint === "0" ? null : sprint;
						return { task_number, to }
				},
		}
}
