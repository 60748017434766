<template>
  <div v-if="title == 'Color'">
    <input
      class="menu-item color"
      type="color"
      @input="action"
      :value="isActive ? isActive() : null"
      list="presetColors"
    />
    <datalist id="presetColors">
      <option>#000000</option>
      />
      <option>#ff0000</option>
      />
      <option>#55B955</option>
      <option>#0000ff</option>
    </datalist>
  </div>
  <button
    v-else
    class="menu-item"
    :class="{
      'is-active': isActive ? isActive() : null,
      'is-disable': canActive ? !canActive() : null,
    }"
    @click="action"
    :title="title"
  >
    <svg class="remix">
      <use :xlink:href="`${remixiconUrl}#ri-${icon}`" />
    </svg>
  </button>
</template>

<script>
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg';

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    action: {
      type: Function,
      required: true,
    },

    type: {
      type: String,
      required: false,
    },

    isActive: {
      type: Function,
      default: null,
    },
    canActive: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      remixiconUrl,
    };
  },
};
</script>

<style lang="scss">
.menu-item {
  width: 1.85rem;
  height: 1.85rem;
  color: #0d0d0d;
  border: none;
  background-color: transparent;
  border-radius: 0.4rem;
  padding: 0.2rem;
  // align-items: center;
  margin-right: 0.25rem;

  svg {
    width: 1rem;
    height: 1rem;
    fill: currentColor;
  }

  &.is-active,
  &:hover {
    color: #fff;
    background-color: #0d0d0d;
  }

  &.is-disable,
  &:hover {
    color: rgb(227, 226, 230);
  }
}
.color {
  width: 4rem;
}
</style>
