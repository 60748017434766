<template>
  <div>
    <template v-for="(item, index) in items">
      <div class="divider" v-if="item.type === 'divider'" :key="`divider${index}`" />
      <menu-item v-else :key="index" v-bind="item" />
    </template>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig';
import { mapState, mapActions } from 'vuex';
import { taskApi } from '../../api';
import MenuItem from './MenuItem.vue';

export default {
  components: {
    MenuItem,
  },

  props: {
    editor: {
      type: Object,
      required: true,
    },
    task: {
      type: Object,
      default: null,
      // required: true,
    },
  },

  computed: {
    ...mapState('project', ['currentProject']),
  },

  methods: {
    ...mapActions('project', ['updateAttachmetsActiveTask']),
  },

  data() {
    return {
      items: [
        {
          icon: 'bold',
          title: 'Bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold'),
        },
        {
          icon: 'italic',
          title: 'Italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic'),
        },
        {
          icon: 'strikethrough',
          title: 'Strike',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike'),
        },
        {
          icon: 'code-view',
          title: 'Code',
          action: () => this.editor.chain().focus().toggleCode().run(),
          isActive: () => this.editor.isActive('code'),
        },
        {
          icon: 'mark-pen-line',
          title: 'Highlight',
          action: () => this.editor.chain().focus().toggleHighlight({ color: '#8ce99a' }).run(),
          isActive: () => this.editor.isActive('highlight'),
        },
        {
          icon: 'font-color',
          title: 'Color',
          action: (event) => {
            return this.editor.chain().focus().setColor(event.target.value).run();
          },
          isActive: () => this.editor.getAttributes('textStyle').color,
        },
        /*{
          type: 'divider',
        },
        */
        {
          icon: 'h-4',
          title: 'Heading 4',
          action: () => this.editor.chain().focus().toggleHeading({ level: 4 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 4 }),
        },
        {
          icon: 'h-5',
          title: 'Heading 5',
          action: () => this.editor.chain().focus().toggleHeading({ level: 5 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 5 }),
        },
        {
          icon: 'paragraph',
          title: 'Paragraph',
          action: () => this.editor.chain().focus().setParagraph().run(),
          isActive: () => this.editor.isActive('paragraph'),
        },
        {
          icon: 'list-unordered',
          title: 'Bullet List',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList'),
        },
        {
          icon: 'list-ordered',
          title: 'Ordered List',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList'),
        },
        {
          icon: 'indent-increase',
          title: 'sink List Item',
          action: () => this.editor.chain().focus().sinkListItem('listItem').run(),
          // isActive: () => this.editor.can().splitListItem('listItem'),
          canActive: () => this.editor.can().splitListItem('listItem'),
        },
        {
          icon: 'list-check-2',
          title: 'Task List',
          action: () => this.editor.chain().focus().toggleTaskList().run(),
          isActive: () => this.editor.isActive('taskList'),
        },
        {
          icon: 'code-box-line',
          title: 'Code Block',
          action: () => this.editor.chain().focus().toggleCodeBlock().run(),
          isActive: () => this.editor.isActive('codeBlock'),
        },
        /*{
          type: 'divider',
        },*/
        {
          icon: 'double-quotes-l',
          title: 'Blockquote',
          action: () => this.editor.chain().focus().toggleBlockquote().run(),
          isActive: () => this.editor.isActive('blockquote'),
        },
        {
          icon: 'link',
          title: 'Set Link',
          action: () => {
            const previousUrl = this.editor.getAttributes('link').href;
            const url = window.prompt('URL', previousUrl);

            // cancelled
            if (url === null) {
              return;
            }

            if (url === '') {
              this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
              return;
            }

            this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
          },
          isActive: () => this.editor.isActive('link'),
        },
        {
          icon: 'link-unlink',
          title: 'Un Set Link',
          action: () => this.editor.chain().focus().unsetLink().run(),
          isActive: () => !this.editor.isActive('link'),
        },
        {
          icon: 'image-add-line',
          title: 'Add Image',
          action: () => {
            const { appLoader } = $themeConfig.app;

            if (this.task) {
              const input = document.createElement('input');
              const acceptFormatList = {
                image: 'image/*',
                video: 'video/*',
              };

              input.setAttribute('type', 'file');
              input.setAttribute('accept', acceptFormatList.image);
              input.click();
              input.onchange = async () => {
                const file = input.files[0];
                const formData = new FormData();
                console.log('file:', file);
                formData.append('filename', file);
                // Insert temporary loading placeholder image
                this.editor.commands.setImage({ src: appLoader });
                // console.log('state:', this.editor.state.selection.$anchor.pos);
                const pos = this.editor.state.selection.$anchor.pos;

                taskApi
                  .uploadFile(this.currentProject, this.task.number, formData)
                  .then((res) => {
                    console.log('data:', res.data);
                    if (res.data.attachment.mime.includes('image')) {
                      // this.editor.chain().focus().deleteSelection().run();

                      this.editor
                        .chain()
                        .focus()
                        .setNodeSelection(pos - 1)
                        .deleteSelection()
                        .run();

                      // Insert uploaded image
                      this.editor.commands.setImage({ src: res.data.attachment.url });
                    }
                    this.updateAttachmetsActiveTask(res.data.attachment);
                    // const data = this.getTaskData(this.task);
                    // this.updateTask(this.task.number, data, false, true);
                  })
                  .catch((error) => {
                    console.log('err:', error.message);
                    this.$bvToast.toast(`Ошибка загрузки данных.`, {
                      title: this.$t('Data Load'),
                      variant: 'danger',
                      solid: true,
                    });
                  });
              };
            }
          },
          isActive: () => this.editor.isActive('image'),
        },
        /*{
          icon: 'separator',
          title: 'Horizontal Rule',
          action: () => this.editor.chain().focus().setHorizontalRule().run(),
        },
        {
          type: 'divider',
        },
        {
          icon: 'text-wrap',
          title: 'Hard Break',
          action: () => this.editor.chain().focus().setHardBreak().run(),
        },
        {
          icon: 'format-clear',
          title: 'Clear Format',
          action: () => this.editor.chain().focus().clearNodes().unsetAllMarks().run(),
        },
        {
          type: 'divider',
        },
        {
          icon: 'arrow-go-back-line',
          title: 'Undo',
          action: () => this.editor.chain().focus().undo().run(),
        },
        {
          icon: 'arrow-go-forward-line',
          title: 'Redo',
          action: () => this.editor.chain().focus().redo().run(),
        },*/
      ],
    };
  },
};
</script>

<style lang="scss">
.divider {
  width: 2px;
  height: 0.325rem;
  background-color: rgba(#000, 0.1);
  margin-left: 0.5rem;
  margin-right: 0.75rem;
}
</style>
